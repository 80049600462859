<template lang="html">
  <section class="gestion-page">
    <template v-if="deparment">
    <bannerHead :dataTitle="'Conversaciones de desarrollo'" ></bannerHead>
      <div class="box-filters container">
        <breacrumbs :dataTitle="'Conversaciones de desarrollo'"></breacrumbs>
      </div>
    </template>
    <section class="container-success" v-if="store.loading">
      <div class="container-text gif">
        <Vue3Lottie :animationData="loadingAnimation" :height="auto" width="100%" />
      </div>
    </section>
    <section class="full-content  gestion" :gestion="slugGestion"  id="menu" v-if="categories && Object.keys(categories).length > 0 ">
        <div class="container">
          <panelGestion  panelGestion :dataGestion="gestions['conversaciones-de-desarrollo']" ></panelGestion>
        </div>
    </section>
    <template v-else>
      <div class="container container-result">
        <p class="title-line -center">No hay resultados</p>
      </div>
    </template>


  </section>
</template>

<script lang="js">
  import { contentStore } from '@/stores/contents'
  import bannerHead from '@/components/banner-head.vue'
  import breacrumbs from '@/components/breacrumbs.vue'
  import panelGestion from '@/components/panel-gestion.vue'
  import { Vue3Lottie } from 'vue3-lottie'
  import 'vue3-lottie/dist/style.css'
  import loadingAnimation from '@/lotties/loading.json'
  import panelOffers from '@/components/panel-offers.vue'
  import EditorJs from "@/components/editorJs.vue";
  const store = contentStore();
  export default {
    name: 'gestion-page',
    props: ['slug', 'slugGestion'],
    components: {
      bannerHead,
      breacrumbs,
      panelGestion,
      // panelOffers,
      // EditorJs,
      Vue3Lottie
    },
    setup() {
      return {
        getDepartamentBySlug: store.getDepartamentBySlug,
        getGestionsByDepartment: store.getGestionsByDepartment,
        getCategoriesByDepartment: store.getCategoriesByDepartment,
        store
      }
    },
    el: '#menu',
    data() {
      return {
        loadingAnimation,
        gestionSelected: null,
        textSearch: null,
        removeSearch: false,
        show: false,
      }
    },
    mounted() {
      this.loadNewDeparment();
    },
    computed: {
      deparment() {
        return this.getDepartamentBySlug('seleccion-y-gestion-del-talento');
      },
      gestions() {
        return this.getGestionsByDepartment('seleccion-y-gestion-del-talento');
      },
      categories() {
        return this.getCategoriesByDepartment('seleccion-y-gestion-del-talento');
      },
      departmentMovilidadInterna() {
        let movilidadMenu = null
        Object.values(this.store.departments).forEach(d => {
          if (d.position == 0) {
            movilidadMenu = d
          }
        })
        return movilidadMenu
      },
    },
    methods: {
      showHide() {
        this.show = !this.show;
      },
      getContentPanel(data) {
        if (data) {
        this.gestionSelected = store.gestions['seleccion-y-gestion-del-talento'].items[data];
        } else {
          this.gestionSelected = null;
        }
      },
      getIfCategorySelect(category) {
        let bool = false;
        if (this.gestionSelected) {
          this.gestionSelected.categories.every(function (ca) {
            if (ca.slug == category.slug) {
              bool = true;
            }
            return !bool;
          });
        }
        return bool;
      },
      searchText() {
        this.loadNewDeparment()
        this.removeSearch = true;
      },
      cleanText() {
        this.textSearch = null;
        this.removeSearch = false;
        this.loadNewDeparment();
      },
      loadNewDeparment() {
        let t = this;
        t.gestionSelected = null;
        store.loading = true;
        let promise = store.loadGestions(
          {
            slug:'seleccion-y-gestion-del-talento',
            textSearch: this.textSearch
          }
        );
        promise.then(function () {
          store.loading = false;
          t.autoSelect();
        });
      },
      autoSelect() {
        this.gestionSelected = null;
        this.infoDepartament = true;
        if (this.slugGestion) {
          this.infoDepartament = false;
          if (this.categories == null || Object.keys(this.categories).length > 0) {
            let gestionSlug = this.slugGestion ? this.slugGestion : Object.keys(this.gestions)[0];
            this.gestionSelected = this.gestions[gestionSlug];
          }
        }
      }
    },
    watch: {
      slug() {
        this.textSearch = null;
        this.loadNewDeparment();
      },
      slugGestion() {
        this.autoSelect();
        this.show = false
      },
    },
  }


</script>

<style scoped lang="scss">
  .content-depart .title-line {
    font-size: 24px;
    margin-bottom: 20px;
    margin-left: -10px;
  }
</style>